import { MAX_PERSONALIZED_NAME_LENGTH } from '../constants';

export const stripHTML = (html: string): string => html.replace(/<[^>]+>/g, '');

const LINK_REGEX =
  // eslint-disable-next-line max-len
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
const linkTemplate = (link: string) =>
  `<a target="_blank" href="${link}">${link}</a>`;
export const contentToHtml = (content: string): string =>
  content
    .replace(LINK_REGEX, (link) => {
      let l = link;
      if (link.startsWith('www')) {
        l = `https://${link}`;
      }
      return linkTemplate(l);
    })
    .split('\n')
    .map((sentence) => `<p>${sentence}</p>`)
    .join('\n');

export const validateGUID = (uuid: string): boolean =>
  new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i'
  ).test(uuid);

export const handleLongFirstName = (firstName?: string) => {
  if (firstName && firstName.length > MAX_PERSONALIZED_NAME_LENGTH) {
    return firstName?.slice(0, MAX_PERSONALIZED_NAME_LENGTH) + '...';
  }
  return firstName;
};

export const countWords = (stringToBreak: string) => {
  // Match words, including those separated by multiple spaces or new lines
  const words = stringToBreak.match(/[^\s]+/g);
  return words ? words.length : 0;

};

export const removeUrlsWithBrackets = (input: string): string => {
  const regex = /\([^\s]*https?:\/\/[^\s]*\)/g;
  return input ? input.replace(regex, '') : '';
};
