

import { Box } from '@wix/design-system';
import React, { FunctionComponent, useContext } from 'react';

import { LoggedIn } from './LoggedIn';
import { NotLoggedIn } from './NotLoggedIn';
import { SearchBarModal } from './SearchBarModal';
import { Context } from '../../../context';
import { isWixelRelated } from '../../../utils/wixelCategory';
import { useStore } from '../../../zustand/stores';

export const HeaderRight: FunctionComponent = () => {
  const { isLoggedInUser, pageTemplateData, pageType } = useContext(Context);
  const categoriesTree = useStore((state) => state.categoriesTreeSlice.categoriesTree);

  const shouldHideSearchBar = isWixelRelated(
    pageType,
    categoriesTree,
    pageTemplateData
  );

  return (
    <Box gap={2}>
      {!shouldHideSearchBar && <SearchBarModal />}
      {!isLoggedInUser ? (
        <NotLoggedIn />
      ) : (
        <LoggedIn />
      )}
    </Box>
  );
};
