import { Category } from '@wix/answers-api';
import { Box } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import ExpandableMenu, { ExpandableMenuType } from './ExpandableMenu';
import { HeaderLeft } from './HeaderLeft';
import { HeaderRight } from './HeaderRight';
import HeaderSmallScreen from './HeaderSmallScreen';
import { WIXEL_CATEGORY_ID } from '../../constants';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import useDisableScroll from '../../hooks/useDisableScroll';
import { useWindowSize } from '../../hooks/useWindowSize';
import { PageType } from '../../types';
import { isMobile, isTablet } from '../../utils/userAgent';
import { useStore } from '../../zustand/stores';

import css from './index.module.scss';

export type Resource = {
  name: string;
  description: string;
  uri: string;
  id: string;
  imageSrc: string;
};

export type Topic = {
  name: string;
  description: string;
  uri: string;
  id: string;
  imageSrc: string;
};

export enum ExpandableMenuState {
  TOPICS = 'topics',
  RESOURCES = 'resources',
  NONE = 'none',
}

const Header: React.FC = () => {
  const categoriesTree = useStore((state) => state.categoriesTreeSlice.categoriesTree);
  const context = useContext(Context);
  const { pageType } = context;
  const isMobileView = isMobile(context);
  const isTabletView = isTablet(context);
  const [isSmallScreen, setIsSmallScreen] = useState(isMobileView || isTabletView);
  const { t } = useTranslation();
  const [expandableMenuState, setExpandableMenuState] =
    React.useState<ExpandableMenuState>(ExpandableMenuState.NONE);
  const showOverlay = expandableMenuState !== ExpandableMenuState.NONE;
  useDisableScroll(showOverlay);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width) {
      setIsSmallScreen(isMobile(context) || isTablet(context));
    }
  }, [context, windowSize.width]);

  const topics: Topic[] = categoriesTree?.filter((category: Category) => category.id !== WIXEL_CATEGORY_ID)
    .map((category: Category) => ({
      name: category.name,
      description: category.shortDescription ?? '',
      uri: category.uri,
      id: category.id,
      imageSrc: category.data.iconSrc,
    }));

  const resourcesNonStudioUser: Resource[] = [
    {
      name: t('header.resources.wix-learn.name'),
      description: t('header.resources.wix-learn.description'),
      uri: 'https://www.wix.com/learn',
      id: 'wix-learn',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/wix-learn.png',
    },
    {
      name: t('header.resources.wix-blog.name'),
      description: t('header.resources.wix-blog.description'),
      uri: 'https://www.wix.com/blog',
      id: 'wix-blog',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/wix-blog.png',
    },
    {
      name: t('header.resources.seo-learning-hub.name'),
      description: t('header.resources.seo-learning-hub.description'),
      uri: 'https://www.wix.com/seo/learn',
      id: 'seo-learning-hub',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/seo-learning-hub.png',
    },
    {
      name: t('header.resources.velo.name'),
      description: t('header.resources.velo.description'),
      uri: 'https://dev.wix.com/docs/develop-websites',
      id: 'velo',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/velo.png',
    },
    {
      name: t('header.resources.hire-a-professional.name'),
      description: t('header.resources.hire-a-professional.description'),
      uri: 'https://www.wix.com/marketplace',
      id: 'hire-a-professional',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/hire-a-professional.png',
    },
  ];


  const resourcesStudioUser: Resource[] = [
    {
      name: t('header.resources.velo.name'),
      description: t('header.resources.velo.description'),
      uri: 'https://dev.wix.com/docs/develop-websites',
      id: 'velo',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/velo.png',
    },
    {
      name: t('header.resources.studio-blog.name'),
      description: t('header.resources.studio-blog.description'),
      uri: 'https://www.wix.com/studio/blog',
      id: 'studio-blog',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/studio-blog.png',
    },
    {
      name: t('header.resources.studio-academy.name'),
      description: t('header.resources.studio-academy.description'),
      uri: 'https://www.wix.com/studio/academy',
      id: 'studio-academy',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/studio-academy.png',
    },
    {
      name: t('header.resources.studio-community-forum.name'),
      description: t('header.resources.studio-community-forum.description'),
      uri: 'https://forum.wixstudio.com/',
      id: 'studio-community-forum',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/studio-community-forum.png',
    },
    {
      name: t('header.resources.customer-care-tickets.name'),
      description: t('header.resources.customer-care-tickets.description'),
      uri: 'https://manage.wix.com/studio/partner-tickets',
      id: 'customer-care-tickets',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/customer-care-tickets.png',
    },
    {
      name: t('header.resources.wix-blog.name'),
      description: t('header.resources.wix-blog.description'),
      uri: 'https://www.wix.com/blog',
      id: 'wix-blog',
      imageSrc:
        'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/wix-blog.png',
    },
  ];

  const resources = context.isWixStudioUser ? resourcesStudioUser : resourcesNonStudioUser;

  const hideExpandableMenu = () => {
    setExpandableMenuState(ExpandableMenuState.NONE);
  };

  return (
    <Box
      className={classNames(css.wrapper, { [css.notHomepage]: pageType !== PageType.Homepage })}
      dataHook={DATA_HOOKS.HEADER}
      align='space-between'
      verticalAlign='middle'
    >
      {isSmallScreen ? (
        <HeaderSmallScreen topics={topics} resources={resources} />
      ) : (
        <>
          <HeaderLeft
            expandableMenuState={expandableMenuState}
            setExpandableMenuState={setExpandableMenuState}
          />
          <HeaderRight />
          <ExpandableMenu
            type={ExpandableMenuType.TOPICS}
            items={topics}
            showMenu={expandableMenuState === ExpandableMenuState.TOPICS}
            hideMenu={hideExpandableMenu}
          />
          <ExpandableMenu
            type={ExpandableMenuType.RESOURCES}
            items={resources}
            showMenu={expandableMenuState === ExpandableMenuState.RESOURCES}
            hideMenu={hideExpandableMenu}
          />
        </>
      )}
    </Box>
  );
};

export default Header;
