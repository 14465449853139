import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import css from './index.module.scss';
import {
  Box,
  Button,
  Heading,
  FloatingNotification,
  Text,
} from '@wix/design-system';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Context } from '../../../context';
import { isMobile, isTablet } from '../../../utils/userAgent';
import { useExperiments } from '@wix/fe-essentials-standalone';
import {
  ALL_PRODUCTS_LABEL_ID,
  BI,
  EXPERIMENTS,
  StorageKeys,
} from '../../../constants';
import { useBI } from '../../../hooks/useBI';
import { DATA_HOOKS } from '../../../dataHooks';
import { pushUrl } from '../../InnerLink';
import {
  setLocalStorage,
  getLocalStorage,
} from '../../../utils/webStorageWrapper';
import { Flag } from '@wix/wix-ui-icons-common';
import { RenderHTML } from '@wix/cc-user-facing-common-components';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useRoadmapHeaderBi } from './bi';
const RoadmapFeatureRequest = dynamic(() => import('../../RoadmapFeatureRequest'));
type RoadmapHeaderProps = {
  roadmapLabels?: {
    id: string;
    name: string;
  }[];
  selectedLabelId?: string;
  isScrolled?: boolean;
};

export const RoadmapHeader: FunctionComponent<RoadmapHeaderProps> = ({
  selectedLabelId,
  roadmapLabels,
  isScrolled,
}) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { query } = useRouter();
  const { locale } = useRouter();
  const { isWixStudioUser } = useContext(Context);
  const isMobileView = isMobile(context);
  const isTabletView = isTablet(context);
  const { sendBIEvent } = useBI();
  const { showRequestFeature } = query;
  const { experiments } = useExperiments({ readOnly: true });
  const [showAutoEnrollmentNotification, setShowAutoEnrollmentNotification] =
    useState(false);
  const biLogger = useRoadmapHeaderBi();

  const midOrNormalClassName = `${css.backgroundWrapper} ${isTabletView ? css.midSize : ''
    }`;

  useEffect(() => {
    const shouldShow =
      experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
      isWixStudioUser &&
      localStorage &&
      !getLocalStorage(StorageKeys.AUTO_ENROLLMENT_NOTIFICATION_DISMISSED);
    if (shouldShow && !showAutoEnrollmentNotification) {
      biLogger.enrollmentFeatureBannerShow({
        content: t('roadmap.page.auto-enrollment-notification'),
      });
    }
    setShowAutoEnrollmentNotification(shouldShow);
  }, [isWixStudioUser, experiments]);

  const dismissAutoEnrollmentNotification = useCallback(() => {
    setShowAutoEnrollmentNotification(false);
    return setLocalStorage(
      StorageKeys.AUTO_ENROLLMENT_NOTIFICATION_DISMISSED,
      'true'
    );
  }, []);

  const setShowFeatureRequestModal = async (show: boolean) => {
    if (isWixStudioUser) {
      const newUrl = new URL(window.location.href);
      if (show) {
        newUrl.searchParams.set('showRequestFeature', 'true');
      } else {
        newUrl.searchParams.delete('showRequestFeature');
        newUrl.searchParams.delete('origin');
      }
      await pushUrl(`${newUrl.pathname}${newUrl.search}`, true);
    }
  };

  const onClickFeatureRequest = async () => {
    await setShowFeatureRequestModal(true);
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: 'request_a_feature',
        clicked_text: 'request_a_feature',
        kb_lang: locale as string,
        item_id:
          selectedLabelId && selectedLabelId !== ALL_PRODUCTS_LABEL_ID
            ? selectedLabelId
            : undefined,
      })
    );
  };

  const className = isMobileView
    ? css.backgroundWrapperMobile
    : midOrNormalClassName;

  return (
    <>
      <Box
        className={`${className} ${css.roadmapV2} ${isScrolled ? css.scrolled : ''
          }`}
      >
        {isMobileView ? (
          <Box
            dataHook={DATA_HOOKS.ROADMAP_HEADER}
            verticalAlign="middle"
            className={css.roadmapMobileHeader}
          >
            <Text
              weight="bold"
              light={isMobileView}
              className={css.roadmapMobileHeaderTitle}
            >
              {t('roadmap.breadcrumbs')}
            </Text>
          </Box>
        ) : (
          <Box
            dataHook={DATA_HOOKS.ROADMAP_HEADER}
            verticalAlign="middle"
            direction="vertical"
            align="left"
            className={css.roadmapHeader}
          >
            <Heading className={css.roadMapHeading} appearance="H3">
              {t('roadmap.page.heading')}
            </Heading>
            {!isTabletView && !isScrolled && (
              <Text
                dataHook={DATA_HOOKS.ROADMAP_SUBHEADER}
                className={css.roadMapSubHeading}
              >
                {t('roadmap.page.sub-heading')}
              </Text>
            )}
          </Box>
        )}
        {isWixStudioUser && !isMobileView && (
          <Button
            className={css.requestFeatureButton}
            onClick={onClickFeatureRequest}
            priority="secondary"
            skin="dark"
            size={'small'}
          >
            {t('roadmap.feature-request')}
          </Button>
        )}
      </Box>
      {showAutoEnrollmentNotification && (
        <FloatingNotification
          onClose={dismissAutoEnrollmentNotification}
          className={css.roadMapAutoEnrollmentNotification}
          prefixIcon={<Flag />}
          type="standard"
          text={
            <RenderHTML html={t('roadmap.page.auto-enrollment-notification')} />
          }
        />
      )}
      {isWixStudioUser && !!showRequestFeature && roadmapLabels && (
        <RoadmapFeatureRequest
          labels={roadmapLabels}
          isOpen={!!showRequestFeature}
          onClose={() => setShowFeatureRequestModal(false)}
        />
      )}
    </>
  );
};
