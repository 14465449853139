import { Category } from '@wix/answers-api';

import { PageTemplateData } from '../components/PageTemplate';
import { WIXEL_CATEGORY_ID } from '../constants';
import { findCategoryInTreeById, getCategoriesPaths } from './categories';
import { PageType } from '../types';

export const isWixelRelated = (
    pageType: PageType,
    categories: Category[],
    pageTemplateData: PageTemplateData
): boolean => {

    if (pageType === PageType.Category) {
        const currentCategoryId = pageTemplateData.itemId;
        const categoriesPath = currentCategoryId ?
            getCategoriesPaths(categories, [currentCategoryId])[currentCategoryId]
                .filter((pathItem) => !!pathItem) : false;

        const isWixCategoryPage = pageTemplateData.itemId === WIXEL_CATEGORY_ID;
        const isWixelSubCategoryPage = categoriesPath && currentCategoryId ?
            !!findCategoryInTreeById(categoriesPath, WIXEL_CATEGORY_ID) : false;

        return isWixCategoryPage || isWixelSubCategoryPage;
    }
    if (pageType === PageType.Article) {
        const currentCategoryId = pageTemplateData?.pageData?.article?.categoryId;
        const categoriesPath = currentCategoryId ?
            getCategoriesPaths(categories, [currentCategoryId])[currentCategoryId]
                .filter((pathItem) => !!pathItem) : false;
        const isWixelSubCategoryPage = categoriesPath && currentCategoryId ?
            !!findCategoryInTreeById(categoriesPath, WIXEL_CATEGORY_ID) : false;
        return isWixelSubCategoryPage;
    }
    return false;
};
