import { noop } from '@wix/answers-lib';
import { CustomModalLayout, Modal, Heading, Input } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  SearchSparkles as SearchSparklesIcon,
  Search as SearchIcon,
} from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SearchBarModalBody } from './SearchBarModalBody';
import { Context } from '../../../../context';
import { DATA_HOOKS } from '../../../../dataHooks';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { useGoToSearchResultsPage } from '../../../../hooks/useGoToSearchResultsPage';
import { useIsGenAnswer } from '../../../../hooks/useIsGenAnswer';
import { useIsIntersecting } from '../../../../hooks/useIsIntersecting';
import { PageType } from '../../../../types';
import { isMobile, isTablet } from '../../../../utils/userAgent';
import { RenderHTML } from '@wix/cc-user-facing-common-components';

import css from './index.module.scss';

export const SearchBarModal: FunctionComponent = () => {
  const pathname = usePathname();
  const { pageType } = useContext(Context);
  const [query, setQuery] = useState('');
  const context = useContext(Context);
  const { showSearchModal, setShowSearchModal, isLoggedInUser, user } = context;
  const isMobileView = isMobile(context);
  const isTabletView = isTablet(context);
  const isSmallScreen = isMobileView || isTabletView;
  const [isCollapsed, setIsCollapsed] = useState(
    isSmallScreen || pageType === PageType.Homepage ? true : false
  );
  const { goToSearchResultsPage } = useGoToSearchResultsPage();
  const isIntersecting = useIsIntersecting(
    DATA_HOOKS.HOME_SEARCH_INPUT,
    '-70px'
  );
  const { t } = useTranslation();
  const isGenAnswerEnabled = useIsGenAnswer();
  useDisableScroll(showSearchModal);
  useEffect(() => {
    if (isSmallScreen) {
      return;
    }
    if (pageType === PageType.Homepage) {
      setIsCollapsed(!isIntersecting);
    } else {
      setIsCollapsed(false);
    }
  }, [isIntersecting, isSmallScreen, pageType]);

  const openModal = () => {
    if (!showSearchModal) {
      setShowSearchModal(true);
    }
  };

  const closeModal = () => {
    setShowSearchModal(false);
    setQuery('');
  };

  const goToSearchResultsPageWrapper = async (overrideQuery?: string) => {
    closeModal();
    await goToSearchResultsPage({
      term: query,
      previousPageType: pageType,
      overrideTerm: overrideQuery,
    });
  };

  useEffect(() => {
    setShowSearchModal(false);
  }, [pathname, setShowSearchModal]);

  return (
    <div
      className={classNames(css.searchBarModal, {
        [css.collapsed]: isCollapsed,
      })}
      onClick={openModal}
    >
      <Input
        className={css.searchBarModalCTA}
        placeholder={
          !isCollapsed ? t('header.search-bar-modal.placeholder') : ''
        }
        onChange={noop}
        value={''}
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_ENTRY_POINT}
        disableEditing={true}
        size="medium"
        prefix={
          <Input.IconAffix>
            {isGenAnswerEnabled ? <SearchSparklesIcon /> : <SearchIcon />}
          </Input.IconAffix>
        }
      />
      <Modal
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL}
        isOpen={showSearchModal}
        onRequestClose={closeModal}
        scrollable={false}
        screen={isMobileView ? 'mobile' : 'desktop'}
      >
        <CustomModalLayout
          dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_BODY}
          onCloseButtonClick={closeModal}
          showHeaderDivider={!isMobileView}
          width={572}
          overflowY="none"
          className={css.searchBarModalLayout}
          title={
            <Heading
              size={isMobileView ? 'large' : 'medium'}
              className={css.searchBarModalTitle}
            >
              <RenderHTML
                html={
                  isLoggedInUser && user?.firstName
                    ? t('search-bar-modal.title-with-user', {
                        firstName: user?.firstName,
                      })
                    : t('search-bar-modal.title-without-user')
                }
              />
            </Heading>
          }
          content={
            <SearchBarModalBody
              goToSearchResultsPage={goToSearchResultsPageWrapper}
              query={query}
              setQuery={setQuery}
            />
          }
        />
      </Modal>
    </div>
  );
};
