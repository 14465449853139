import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import css from './index.module.scss';
import { Box, Text, TextButton } from '@wix/design-system';
import { Context } from '../../../context';
import { RoadmapETAorVotedFilter } from '../../../types';
import { useRouter } from 'next/router';
import { RenderHTML } from '@wix/cc-user-facing-common-components';
import { loginWithAnswersSSO } from '../../../utils/login';

type RoadmapEmptyStateProps = {
  ETAorVotedFilter: RoadmapETAorVotedFilter;
};

export const RoadmapEmptyState: FunctionComponent<RoadmapEmptyStateProps> = ({
  ETAorVotedFilter,
}) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { locale } = useRouter();

  return (
    <Box
      direction="vertical"
      verticalAlign="top"
      className={`${css.emptyState} ${css.roadmapV2}`}
      align="center"
    >
      {ETAorVotedFilter === RoadmapETAorVotedFilter.FOLLOWING ? (
        <>
          <Text
            className={css.emptyStateHeader}
            type="H5"
            weight="bold"
            size="medium"
          >
            {context.isLoggedInUser ? (
              t('roadmap.empty-state.following.header')
            ) : (
              <TextButton
                onClick={
                  locale
                    ? () => loginWithAnswersSSO(location.href, locale)
                    : undefined
                }
                underline="always"
                size="medium"
                className={css.loginButton}
              >
                {t('roadmap.article-list.empty-state.log-in')}
              </TextButton>
            )}
            {!context.isLoggedInUser &&
              t('roadmap.empty-state.following.log-in.header')}
          </Text>
          <Text className={css.emptyStateSubHeaderFirstRow} size="medium">
            <RenderHTML
              html={
                context.isLoggedInUser
                  ? t('roadmap.empty-state-sub-header.following.first-row')
                  : t(
                      'roadmap.empty-state-sub-header.following.first-row.log-in'
                    )
              }
            />
          </Text>
        </>
      ) : (
        <>
          <Text
            className={css.emptyStateHeader}
            type="H5"
            weight="bold"
            size="medium"
          >
            {t('roadmap.empty-state-header.v2')}
          </Text>
          <Text className={css.emptyStateSubHeaderFirstRow} size="medium">
            {t('roadmap.empty-state-sub-header.first-row.v2')}
          </Text>
        </>
      )}
    </Box>
  );
};
